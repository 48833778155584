import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener("load", () => {
  console.log("js running.");

  // All variables that are used in this file
  const animateElementsSequence = document.querySelectorAll(
    ".animate-elements-sequence"
  ); // Select all elements
  const containers = document.querySelectorAll(".image-and-subtitle-container");
  const desktopNavLinkItems = document.querySelectorAll(".desktop-nav-links a");
  const desktopNavLinks = document.querySelectorAll(".desktop-nav-links");
  const galleryOverlay = document.querySelector(".gallery-overlay");
  const hamburgerIcon = document.getElementById("hamburger"); // Assuming this is the ID of your hamburger icon
  const header = document.querySelector(".description span");
  const innerNav = document.getElementById("inner-nav");
  const innerNavLinks = document.querySelectorAll(".inner-nav-links"); // Select all navigation link containers
  const innerNavOverlay = document.getElementById("inner-nav-overlay");
  const logoOne = document.querySelector(".logo-one");
  const logoTwo = document.querySelector(".logo-two");
  const navBtn = document.querySelector(".nav-btn"); // Select the nav button
  let menuOpen = false; // A flag to track the state of the menu
  const thresholdValue = 0;
  var expandImageOverlay = document.querySelector(".left-expand-image-overlay");
  var animationOne = document.getElementById("animationOne");
  var cornerBottomSvg = document.querySelector(".corner-bottom-svg");
  var cornerBottomSvg = document.querySelector(".corner-top-svg");
  var svgIcon = document.getElementById("svg-icon");
  const galleryThreeAnimation = document.querySelector(
    ".gallery-three-aimation"
  );
  const centralImage = document.querySelector(".central-image");
  var svgIcon = document.getElementById("svg-icon");
  const elements = gsap.utils.toArray(".fade-in-element");
  const initialViewportHeight = window.innerHeight;

  // Set scroll position to 0
  window.scrollTo(0, 0);

  // Desktop nav animation
  function animateLinks() {
    // Check if elements are selected
    if (desktopNavLinkItems.length !== 0) {
      // Apply staggered animation
      gsap.to(desktopNavLinkItems, {
        duration: 0.5,
        opacity: 1,
        delay: 1.5, // Adjust as needed
        stagger: 0.5, // Stagger the start of each link's animation
      });
    }

    const links = gsap.utils.toArray(".fade-in-element-desktop");
    gsap.set(links, {
      autoAlpha: 0,
    }); // Set initial state of links to invisible

    const timeline = gsap.timeline({
      defaults: {
        duration: 0.5,
        ease: "power1.out",
      },
    });

    links.forEach((link, index) => {
      timeline.to(
        link,
        {
          autoAlpha: 1,
        },
        index * 0.3
      );
    });
  }

  // Open close menu
  const toggleNavDisplay = () => {
    if (!menuOpen) {
      // Opening the menu
      // Slide in the overlay from the right
      gsap.fromTo(
        innerNavOverlay,
        {
          x: "100%",
          autoAlpha: 0,
        },
        {
          x: "0%",
          autoAlpha: 1,
          duration: 0.5,
        }
      );
      innerNavOverlay.classList.remove("hidden", "pointer-events-none");
      innerNavOverlay.classList.add("block", "pointer-events-auto");

      // Fade in the innerNav after the overlay animation
      gsap.to(innerNav, {
        autoAlpha: 1,
        duration: 0.5,
        delay: 0.5,
      });
      innerNav.classList.remove("hidden", "pointer-events-none");
      innerNav.classList.add("block", "pointer-events-auto");
    } else {
      // Closing the menu
      // Fade out the innerNav first
      gsap.to(innerNav, {
        autoAlpha: 0,
        duration: 0.3,
        onComplete: () => {
          innerNav.classList.add("hidden", "pointer-events-none");
          innerNav.classList.remove("block", "pointer-events-auto");

          // Slide out the overlay after the innerNav is hidden
          gsap.to(innerNavOverlay, {
            x: "100%",
            autoAlpha: 0,
            duration: 0.5,
          });
          innerNavOverlay.classList.add("hidden", "pointer-events-none");
          innerNavOverlay.classList.remove("block", "pointer-events-auto");
        },
      });
    }
    menuOpen = !menuOpen; // Toggle the state of the menu
  };

  const toggleNavLinks = () => {
    if (window.scrollY > 1) {
      desktopNavLinks.forEach((link) => {
        // Check if the links are currently visible
        if (link.style.opacity === "1" || link.style.opacity === "") {
          // Hide the links
          gsap.to(link, {
            duration: 0.3,
            autoAlpha: 0,
            y: -20,
          });
        } else {
          // Show the links
          gsap.to(link, {
            duration: 0.3,
            autoAlpha: 1,
            y: 0,
          });
        }
      });
      document.querySelector(".logo-two").classList.remove("active");
    }
  };

  // Header text animation
  if (header) {
    const regex = /<i>.*?<\/i>|<em>.*?<\/em>|./g;
    const text = header.innerHTML.replace(/&nbsp;/g, " ");
    const matches = text.match(regex);

    header.innerHTML = matches
      .map((match) => {
        if (match.startsWith("<i>") || match.startsWith("<em>")) {
          const content = match.slice(
            match.indexOf(">") + 1,
            match.lastIndexOf("<")
          );
          return content
            .split("")
            .map((char) => {
              return `<span class="letter text-height italic font-didot tracking-tighter" style="opacity: 0">${char}</span>`;
            })
            .join("");
        } else {
          return `<span class="letter" style="opacity: 0">${match}</span>`;
        }
      })
      .join("");

    ScrollTrigger.create({
      trigger: ".header",
      start: "top center",
      onEnter: () => {
        gsap.to(".description .letter", {
          opacity: 1,
          y: 0,
          duration: 0.5,
          stagger: 0.02,
          ease: "back.out(1.7)",
        });
      },
    });
  }

  window.addEventListener("scroll", function () {
    const wrapper = document.querySelector(".hide-show-desktop-links");

    // Ensure wrapper is not null
    if (wrapper) {
      if (window.innerWidth > 1024) {
        if (window.scrollY > 40 && wrapper.classList.contains("hidden")) {
          wrapper.classList.remove("hidden");
          gsap.to(wrapper, {
            autoAlpha: 1,
            duration: 0.5,
          });
          animateLinks();
        } else if (
          window.scrollY <= 40 &&
          !wrapper.classList.contains("hidden")
        ) {
          gsap.to(wrapper, {
            autoAlpha: 0,
            duration: 0.5,
            onComplete: () => wrapper.classList.add("hidden"),
          });
        }
      }
    }
  });

  // Function to handle mouseenter event
  function handleMouseEnter(container) {
    // Set initial opacity and class for galleryOverlay
    galleryOverlay.style.opacity = "0";
    galleryOverlay.classList.add("lg:flex");
    setTimeout(() => {
      galleryOverlay.style.opacity = "0.9";
    }, 10);

    // Adjust opacity for other containers
    containers.forEach((otherContainer) => {
      if (otherContainer !== container) {
        const otherOverlay = otherContainer.querySelector(".animated-overlay");
        otherOverlay.style.opacity = "0.9";
      }
    });

    // Adjust opacity for non-hovered .overlay-text-animation-service elements
    document
      .querySelectorAll(".overlay-text-animation-services")
      .forEach((otherOverlayText) => {
        if (!container.contains(otherOverlayText)) {
          otherOverlayText.style.opacity = "0.1";
        }
      });

    // Toggle class for text animation
    const overlayTextAnimation = container.querySelector(
      ".overlay-text-animation"
    );
    if (overlayTextAnimation) {
      overlayTextAnimation.classList.toggle("text-black");
      overlayTextAnimation.classList.toggle("text-white");
    }
  }

  // Function to handle mouseleave event
  function handleMouseLeave(container) {
    // Reset opacity and class for galleryOverlay
    galleryOverlay.style.opacity = "0";
    galleryOverlay.classList.remove("lg:flex");

    // Reset opacity for all containers
    containers.forEach((otherContainer) => {
      const otherOverlay = otherContainer.querySelector(".animated-overlay");
      otherOverlay.style.opacity = "0";
    });

    // Reset opacity for all .overlay-text-animation-service elements
    document
      .querySelectorAll(".overlay-text-animation-services")
      .forEach((otherOverlayText) => {
        otherOverlayText.style.opacity = "1";
      });

    // Toggle class for text animation
    const overlayTextAnimation = container.querySelector(
      ".overlay-text-animation"
    );
    if (overlayTextAnimation) {
      overlayTextAnimation.classList.toggle("text-black");
      overlayTextAnimation.classList.toggle("text-white");
    }
  }

  // Attach event listeners to containers
  containers.forEach((container) => {
    container.addEventListener("mouseenter", () => handleMouseEnter(container));
    container.addEventListener("mouseleave", () => handleMouseLeave(container));
  });

  // Define the animation for fading out the text
  gsap.utils.toArray(".text-fade-out").forEach((text) => {
    gsap.to(text, {
      opacity: 0, // Fade to 0 opacity
      ease: "none",
      scrollTrigger: {
        trigger: "#light-section-text-animation",
        start: "top top",
        end: "+=200", // Adjust as needed
        scrub: 1,
      },
    });
  });
  // Define the animation for fading in the text
  gsap.utils.toArray(".text-fade-in").forEach((text) => {
    gsap.to(text, {
      opacity: 1, // Fade to 1 opacity
      ease: "none",
      scrollTrigger: {
        trigger: "#light-section-image-animation",
        start: "+=200",
        end: "+=500",
        scrub: true,
      },
    });
  });

  // Define the animation for fading out the text
  gsap.utils.toArray(".image-overlay").forEach((text) => {
    gsap.to(text, {
      opacity: 0.5, // Fade to 1 opacity
      ease: "none",
      scrollTrigger: {
        trigger: "#light-section-image-animation",
        start: "+=200",
        end: "+=500",
        scrub: true,
      },
    });
  });

  if (expandImageOverlay) {
    gsap.to(".left-expand-image-overlay", {
      scrollTrigger: {
        trigger: "#light-section-image-animation",
        scrub: 1,
        start: "top top",
        end: "+=500",
      },
      scaleX: 0, // Scale down to 0
      transformOrigin: "left center",
      ease: "none",
    });
  }

  if (animationOne) {
    // Existing ScrollTrigger for pinning
    ScrollTrigger.create({
      trigger: "#animationOne",
      start: "top top",
      end: "+=1000",
      pin: "#animationOneContent",
    });
  }

  if ((cornerBottomSvg, cornerBottomSvg)) {
    gsap.to(".corner-top-svg, .corner-bottom-svg", {
      scrollTrigger: {
        trigger: "#light-section-image-animation",
        scrub: true,
        start: "top top",
        end: "+=500",
      },
      x: "-37.5vw", // Move to the left by 100% of the viewport width
      ease: "none",
    });
  }

  if (svgIcon) {
    gsap.to("#svg-icon", {
      scrollTrigger: {
        trigger: "#svg-icon",
        start: "top center", // Change trigger conditions as needed
        onEnter: () =>
          document.querySelector("#svg-icon").classList.add("active"),
        onLeaveBack: () =>
          document.querySelector("#svg-icon").classList.remove("active"),
        markers: false, // Remove this line in production; it's just for debugging
      },
    });
  }

  // gallery three animation
  // Create a timeline with ScrollTrigger
  if (galleryThreeAnimation) {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".gallery-three-aimation",
        start: "top top",
        end: "bottom center",
        scrub: 1,
        pin: true,
        onUpdate: (self) => {},
      },
    });

    if (centralImage) {
      // Animate the central image (ensure it starts at its original CSS size)
      tl.fromTo(
        ".central-image",
        {
          width: "464px",
          height: "596px",
        }, // Starting size from CSS
        {
          width: "100%",
          ease: "none",
        }, // End size
        0
      );

      // Animate the overlay's opacity
      tl.fromTo(
        ".central-image .central-image-overlay",
        {
          opacity: 0,
        }, // Starting opacity
        {
          opacity: 1,
          ease: "none",
        }, // End opacity
        0
      );

      // Animate the text's opacity (make sure to fade in after the overlay is visible)
      tl.fromTo(
        ".central-image .central-image-overlay .gallery-three-text",
        {
          opacity: 0,
        }, // Starting opacity
        {
          opacity: 1,
          ease: "none",
          duration: 0.25,
        }, // End opacity and duration
        "+=0.005" // Delay after the overlay becomes fully visible
      );

      // Animate the left inner image with faster opacity reduction
      tl.fromTo(
        ".my-image-container-lg-inner.left",
        {
          width: "310px",
          height: "464px",
          opacity: 1,
        },
        {
          x: "-100%", // Move to the left
          width: "0%",
          ease: "none",
        },
        0
      ).to(
        ".my-image-container-lg-inner.left",
        {
          opacity: 0,
          duration: 0.2,
          delay: 0.15,
        },
        0
      ); // Shorter duration for opacity

      // Animate the right inner image with faster opacity reduction
      tl.fromTo(
        ".my-image-container-lg-inner.right",
        {
          width: "310px",
          height: "464px",
          opacity: 1,
        },
        {
          x: "100%", // Move to the right
          width: "0%",
          ease: "none",
        },
        0
      ).to(
        ".my-image-container-lg-inner.right",
        {
          opacity: 0,
          duration: 0.2,
          delay: 0.15,
        },
        0
      ); // Shorter duration for opacity

      // Animate 'left outer' other images with faster opacity reduction
      tl.fromTo(
        ".my-image-container-lg-outer.left",
        {
          width: "158px",
          height: "236px",
          opacity: 1,
        },
        {
          x: "-100%", // Move to the left
          width: "0%",
          ease: "none",
        },
        0
      ).to(
        ".my-image-container-lg-outer.left",
        {
          opacity: 0,
          duration: 0.25,
        },
        0
      ); // Shorter duration for opacity

      // Animate 'right outer' other images with faster opacity reduction
      tl.fromTo(
        ".my-image-container-lg-outer.right",
        {
          width: "158px",
          height: "236px",
          opacity: 1,
        },
        {
          x: "100%", // Move to the right
          width: "0%",
          ease: "none",
        },
        0
      ).to(
        ".my-image-container-lg-outer.right",
        {
          opacity: 0,
          duration: 0.25,
        },
        0
      ); // Shorter duration for opacity
    }
  }

  // mobile specific animations
  const elementPositions = elements.map((element) => {
    return element.getBoundingClientRect().top;
  });

  // Batch write: Setup animations using the stored positions
  elements.forEach((element, index) => {
    let startPosition = "top+=80 bottom"; // Default start position

    // Calculate the delay
    let delay = 0;
    if (elementPositions[index] <= initialViewportHeight) {
      // Apply staggered delay only to elements within the initial viewport
      delay = index * 0.3;
      startPosition = "top bottom";
    }

    gsap.fromTo(
      element,
      { autoAlpha: 0 }, // Start state: initially invisible
      {
        duration: 2,
        autoAlpha: 1, // End state: fully visible
        scrollTrigger: {
          trigger: element,
          start: startPosition,
          toggleActions: "play none none none",
        },
        delay: delay, // Apply calculated delay
      }
    );
  });

  // all event listeners
  navBtn.addEventListener("click", toggleNavLinks);
  hamburger.addEventListener("click", toggleNavLinks);
  hamburgerIcon.addEventListener("click", toggleNavDisplay);
});
