window.addEventListener('load', () => {
  // Create the lightbox element
  const lightbox = document.createElement('div');
  lightbox.id = 'lightbox';
  lightbox.classList.add('hidden', 'fixed', 'top-0', 'left-0', 'w-full', 'h-full', 'z-50', 'bg-black', 'bg-opacity-80', 'flex', 'items-center', 'justify-center');
  lightbox.innerHTML = `
      <div class="lightbox-content rounded-[32px]">
          <img id="lightbox-image" class="max-w-full max-h-full rounded-[32px]" src="" alt="Enlarged Image"/>
          <span id="close-lightbox" class="close-lightbox absolute top-5 right-10 text-white text-4xl cursor-pointer">&times;</span>
      </div>
  `;
  document.body.appendChild(lightbox);

  // Function to open the lightbox
  function openLightbox(event) {
      event.preventDefault();
      const imageUrl = event.currentTarget.href;
      document.getElementById('lightbox-image').src = imageUrl;
      lightbox.classList.remove('hidden');
  }

  // Attach event listeners to the lightbox triggers
  const lightboxTriggers = document.querySelectorAll('.lightbox-trigger');
  lightboxTriggers.forEach(trigger => {
      trigger.addEventListener('click', openLightbox);
  });

  // Close lightbox functionality
  document.getElementById('close-lightbox').addEventListener('click', () => {
      lightbox.classList.add('hidden');
  });

  // Close lightbox when clicking outside the image or pressing Escape
  lightbox.addEventListener('click', (e) => {
      if (e.target !== document.getElementById('lightbox-image')) {
          lightbox.classList.add('hidden');
      }
  });

  document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
          lightbox.classList.add('hidden');
      }
  });
});
